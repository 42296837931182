<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Gestioni Operative</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <strong class="capitalize">{{ $route.params.id }}</strong>
          <div style="display:block;">
              <div class="bottoni"><ion-button @click="$router.push('/folder/accounts-list')">GESTIONE ACCOUNTS</ion-button></div> 
              <div v-if=" $store.state.userRole==7">
                <div class="bottoni"><ion-button @click="$router.push('/folder/companies-list')">GESTIONE COMPANIES</ion-button></div>
                <div class="bottoni"><ion-button @click="$router.push('/folder/costs-list')">GESTIONE COSTI</ion-button></div>
                <div class="bottoni"><ion-button @click="$router.push('/folder/task-projects-list')">GESTIONE TASK PROJECTS</ion-button></div>  
                <div class="bottoni"><ion-button @click="$router.push('/folder/users-list')">GESTIONE ACCESSI UTENTI</ion-button></div>  
              </div>     
          </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButton,
    IonButtons, 
    IonContent, 
    IonHeader, 
    IonMenuButton, 
    IonPage, 
    IonTitle, 
    IonToolbar 
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonMenuButton,
      IonPage,
      IonTitle,
      IonToolbar
    },
    methods:{
      getUser(){
        this.axios.post(this.apiUrl + 'get-user', {}, this.headers)
        //.then(async (response) => {
        //  console.log(response)
        //})
      }
    },
    ionViewWillEnter: function(){
      this.getUser();
    }
  }
</script>

<style scoped>
  #container {
    min-height:100%;
    display:flex;
    align-items:top;
    justify-content:center;
    flex-wrap:wrap;
  }

  #container strong {
    font-size: 20px;
    line-height: 26px;
  }

  #container a {
    text-decoration: none;
  }

  ion-button{
      width:300px;
  }

  .bottoni{
    width:100%;
    margin-top:2%;
  }
</style>